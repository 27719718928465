import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import {
  Box,
  Flex,
  Image,
  SimpleGrid,
  Skeleton,
  SkeletonText,
  Text,
} from "@chakra-ui/react"
import { HeadFC, Link, PageProps } from "gatsby"
import React from "react"
import { HeadSEO } from "../components/head/"
import { IReceipt } from "../dtos/IReceipts.dto"
import { useReceipts, useReceiptsHeaders } from "../hooks/useReceipts"
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter"

const ReceitasPage: React.FC<PageProps> = () => {
  const [firstReceipt, setFirstReceipt] = React.useState<
    IReceipt | null | undefined
  >(null)
  const [lastReceipt, setLastReceipt] = React.useState<IReceipt[]>([])

  const [page, setPage] = React.useState(1)

  const [theme, setTheme] = React.useState("Todos")

  const { data: receipts, isLoading } = useReceipts(page, {})

  const { data: headers } = useReceiptsHeaders(page, {})

  React.useEffect(() => {
    if (receipts) {
      setFirstReceipt(receipts?.shift())
      setLastReceipt(receipts)
    }
  }, [receipts])

  React.useEffect(() => {
    if (theme != "Todos") {
      const filteredReceipts = receipts?.filter(
        i => i.filtro == theme.toLowerCase(),
      )
      filteredReceipts && setLastReceipt(filteredReceipts)
    } else {
      receipts && setLastReceipt(receipts)
    }
  }, [theme])

  const handlePrevPage = () => {
    page > 1 && page <= headers?.["x-wp-totalpages"] ? setPage(page - 1) : false
  }
  const handleNextPage = () => {
    page >= 1 && page < headers?.["x-wp-totalpages"] ? setPage(page + 1) : false
  }

  return (
    <body id="receitas">
      <div className="container header">
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center py-5">
            <span className="h1 text-title text-center">Receitas</span>
            <span
              className="h4 text-paragraph text-center"
              style={{
                textAlign: "center",
                maxWidth: "600px",
              }}
            >
              Nosso catálogo de receitas fresquinhas, práticas e gostosas para
              todos os momentos do dia
            </span>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="last-receita-wrapper">
          <div className="ultima-receita">
            {!isLoading && firstReceipt && (
              <Link
                to={"/receitas/" + firstReceipt.slug}
                className="receita-item"
              >
                <Image
                  src={firstReceipt?.imagem_de_capa?.guid}
                  alt="Receita bolo"
                />
                <div className="receita-title">
                  {firstReceipt.title.rendered}
                </div>
                <div className="d-flex flex-row justify-content-end align-items-center">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
                      fill="#495754"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 5C12.5523 5 13 5.44772 13 6V11H16.5C17.0523 11 17.5 11.4477 17.5 12C17.5 12.5523 17.0523 13 16.5 13H12C11.4477 13 11 12.5523 11 12V6C11 5.44772 11.4477 5 12 5Z"
                      fill="#495754"
                    />
                  </svg>
                  <span className="cooking-time-text p-2">
                    {firstReceipt.tempo_de_preparo} minutos
                  </span>
                </div>
              </Link>
            )}

            {isLoading && (
              <Box borderRadius={25} w="100%" h="600px">
                <Skeleton borderRadius={25} w="100%" h="600px" />
              </Box>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <Flex direction={"column"} margin="70px 0px 0px 0px" wrap={"wrap"}>
          <hr />

          <Flex margin="20px 0px">
            <div className="receitas-categorias">
              <a
                href="javascript:void(0)"
                onClick={() => setTheme("Todos")}
                className={
                  theme == "Todos"
                    ? " categoria-item active"
                    : " categoria-item"
                }
              >
                <span className="h6">Todas</span>
              </a>

              <a
                href="javascript:void(0)"
                onClick={() => setTheme("Doces")}
                className={
                  theme == "Doces"
                    ? " categoria-item active"
                    : " categoria-item"
                }
              >
                <span className="h6">Doces</span>
              </a>

              <a
                href="javascript:void(0)"
                onClick={() => setTheme("Salgados")}
                className={
                  theme == "Salgados"
                    ? " categoria-item active"
                    : " categoria-item"
                }
              >
                <span className="h6">Salgados</span>
              </a>
            </div>
          </Flex>
        </Flex>

        <SimpleGrid minChildWidth="16rem" margin="0px 0px 20px 0px" gap={6}>
          {!isLoading &&
            lastReceipt.map(receipt => (
              <Box
                padding="20px 20px"
                borderRadius={24}
                background="#F2F4F3"
                h="100%"
                w="100%"
                key={receipt.id}
              >
                <Link to={"/receitas/" + receipt.slug}>
                  <Flex direction="column">
                    <div className="card-receita-img">
                      <Image
                        h="13rem"
                        w="100%"
                        marginBottom={5}
                        borderRadius={20}
                        objectFit="cover"
                        src={receipt.imagem_de_capa.guid}
                        alt={receipt.title.rendered}
                      />
                    </div>

                    <span className="receita-duracao">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_600_27363)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.99935 2.00008C4.68564 2.00008 1.99935 4.68637 1.99935 8.00008C1.99935 11.3138 4.68564 14.0001 7.99935 14.0001C11.3131 14.0001 13.9993 11.3138 13.9993 8.00008C13.9993 4.68637 11.3131 2.00008 7.99935 2.00008ZM0.666016 8.00008C0.666016 3.94999 3.94926 0.666748 7.99935 0.666748C12.0494 0.666748 15.3327 3.94999 15.3327 8.00008C15.3327 12.0502 12.0494 15.3334 7.99935 15.3334C3.94926 15.3334 0.666016 12.0502 0.666016 8.00008Z"
                            fill="#00615C"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.00065 3.33325C8.36884 3.33325 8.66732 3.63173 8.66732 3.99992V7.33325H11.0007C11.3688 7.33325 11.6673 7.63173 11.6673 7.99992C11.6673 8.36811 11.3688 8.66659 11.0007 8.66659H8.00065C7.63246 8.66659 7.33398 8.36811 7.33398 7.99992V3.99992C7.33398 3.63173 7.63246 3.33325 8.00065 3.33325Z"
                            fill="#00615C"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_600_27363">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span>{receipt.tempo_de_preparo} minutos</span>
                    </span>

                    <span className="receita-title">
                      {receipt.title.rendered}
                    </span>
                  </Flex>
                </Link>
              </Box>
            ))}

          {isLoading &&
            Array.from({ length: 3 }).map((_, index) => (
              <Box
                padding="20px 20px"
                borderRadius={24}
                background="#F2F4F3"
                h="100%"
                w="100%"
                key={index}
              >
                <Skeleton />
                <Flex direction="column">
                  <Skeleton
                    h="13rem"
                    w="100%"
                    marginBottom={5}
                    borderRadius={20}
                    objectFit="cover"
                    background="#202020"
                  />

                  <SkeletonText w="100%" />
                </Flex>
              </Box>
            ))}
        </SimpleGrid>

        <Flex justify="center" align="center">
          <button onClick={handlePrevPage}>
            <ChevronLeftIcon boxSize={7} />
          </button>
          <Text as="p" fontSize={"1.25rem"} margin="0px">
            Página {page} de {headers?.["x-wp-totalpages"]}{" "}
          </Text>
          <button onClick={handleNextPage}>
            <ChevronRightIcon boxSize={7} />
          </button>
        </Flex>
      </div>
    </body>
  )
}

export default ReceitasPage

export const Head: HeadFC = () =>
  HeadSEO(capitalizeFirstLetter(`Receitas | Verde Campo`))

import { useQuery } from "@tanstack/react-query"
import { api } from "../services/api"
import { IQuery, IQueryPost } from "../dtos/IQuery.dto"
import { IReceipt, IReceipts } from "../dtos/IReceipts.dto"

export const useReceipts = (pageNumber: number, query?: IQuery) => {
  return useQuery(["receipts", pageNumber], async () => {
    const limit = query?.per_page || 10
    const { data } = await api.get<IReceipts>(
      `/wp/v2/receitas?page=${pageNumber}&per_page=${limit}`,
    )
    return data
  })
}

export const useReceiptsHeaders = (pageNumber: number, query?: IQuery) => {
  return useQuery(["receiptsheaders", pageNumber], async () => {
    const limit = query?.per_page || 10
    const { headers } = await api.get<IReceipts>(
      `/wp/v2/receitas?page=${pageNumber}&per_page=${limit}`,
    )
    return headers
  })
}

export const useReceiptsById = (query?: IQueryPost) => {
  const id = query?.id
  return useQuery(["receipt", id], async () => {
    const { data } = await api.get<IReceipt>(`/wp/v2/receitas/${id}`)
    return data
  })
}

export const useReceiptsOrder = (query?: IQuery) => {
  const orderby = query?.orderby || "date"
  const order = query?.order || "desc"
  const page = query?.page || 1
  const limit = query?.per_page || 10

  return useQuery(["receiptsorderby", page], async () => {
    const { data } = await api.get<IReceipts>(
      `/wp/v2/receitas/?orderby=${orderby}&order=${order}&page=${page}&per_page=${limit}`,
    )
    return data
  })
}
